import { ReactComponent as Polkadot } from "../../../components/icons/polkadot.svg";
import { polkadotColor } from "./common";

const joystream = {
  name: "Joystream",
  icon: <Polkadot />,
  sub: "joystream",
  value: "joystream",
  chain: "joystream",
  symbol: "JOY",
  decimals: 10,
  chainIcon: "originalPolkadot",
  ...polkadotColor,
  buttonColor: "#E6007A",
  logo: "logo-img-2",
  modules: {
    identity: false,
    multisig: true,
    vestings: true,
    proxy: true,
  },
  treasuryWebsite: "",
  subSquareWebsite: "",
  nodes: [{ name: "Jsgenesis", url: "wss://rpc.joystream.org:9944" }],
  useOnChainBlockData: true,
};

export default joystream;
