export const polkadotColor = {
  color: "#E6007A",
  colorSecondary: "rgba(230, 0, 122, 0.1)",
};

export const kusamaColor = {
  color: "#3765DC",
  colorSecondary: "rgba(55, 101, 220, 0.1)",
  buttonColor: "#000000",
};

export const paseoColor = {
  color: "#1CC776",
  colorSecondary: "rgba(28,199,118, 0.1)",
};
